importJobEnglishLevelOptions = checkWindowObject('importJobEnglishLevelOptions')
importJobWorkingHourStartOptions = checkWindowObject('importJobWorkingHourStartOptions')
importJobWorkingHourEndOptions = checkWindowObject('importJobWorkingHourEndOptions')
importJobPlaceholderTranslations = checkWindowObject('importJobPlaceholderTranslations')

function checkWindowObject(obj) { return typeof window[obj] !== 'undefined' && window[obj] }

let importJobColumns = [
  {
    data: 'import_status',
    renderer: 'importStatusRenderer',
    readOnly: true
  },
  {
    data: 'title',
    type: 'text',
    placeholder: importJobPlaceholderTranslations['title'],
    validator: 'titleValidator',
    width: 255
  },
  {
    data: 'division_id',
    type: 'dropdown',
    placeholder: importJobPlaceholderTranslations['division_id'],
    width: 150
  },
  {
    data: 'salary_min',
    type: 'numeric',
    numericFormat: {
      pattern: '0,0'
    },
    placeholder: importJobPlaceholderTranslations['salary_min'],
    width: 120
  },
  {
    data: 'salary_max',
    type: 'numeric',
    numericFormat: {
      pattern: '0,0'
    },
    placeholder: importJobPlaceholderTranslations['salary_max'],
    validator: 'salaryMaxValidator',
    width: 120
  },
  {
    data: 'working_location_ids',
    type: 'dropdown',
    placeholder: importJobPlaceholderTranslations['working_location_ids'],
    validator: 'workingLocationValidator',
    readOnly: true,
    width: 200
  },
  {
    data: 'description',
    type: 'text',
    placeholder: importJobPlaceholderTranslations['description'],
    validator: 'textLengthValidator',
    width: 255
  },
  {
    data: 'requirement',
    type: 'text',
    placeholder: importJobPlaceholderTranslations['requirement'],
    validator: 'textLengthValidator',
    width: 255
  },
  {
    data: 'english_level_id',
    type: 'dropdown',
    placeholder: importJobPlaceholderTranslations['english_level_id'],
    readOnly: true,
    width: 150
  },
  {
    data: 'working_hour_start',
    type: 'dropdown',
    placeholder: importJobPlaceholderTranslations['working_hour_start'],
    width: 200
  },
  {
    data: 'working_hour_end',
    type: 'dropdown',
    preventOverflow: 'vertical',
    placeholder: importJobPlaceholderTranslations['working_hour_end'],
    width: 200
  },
  {
    data: 'holiday',
    type: 'text',
    placeholder: importJobPlaceholderTranslations['holiday'],
    validator: 'stringLengthValidator',
    width: 255
  },
  {
    data: 'benefit',
    type: 'text',
    placeholder: importJobPlaceholderTranslations['benefit'],
    validator: 'textLengthValidator',
    width: 255
  },
  {
    data: 'report_to',
    type: 'text',
    placeholder: importJobPlaceholderTranslations['report_to'],
    validator: 'stringLengthValidator',
    width: 255
  }
]

module.exports = { importJobColumns }
