function closeOtherSelection(otherSelectionContainer) {
  otherSelectionContainer.forEach((element) => {
    if (element.classList.contains('active')) { element.classList.remove('active') }
  })
}

function assignSelectionRow(selectionContainer, row) {
  const jobRow = selectionContainer.querySelector('input[name="job[row]"]')

  jobRow && (jobRow.value = row)
}

function deSelect(selectionContainer) {
  const ssDeselect = selectionContainer.querySelector('.ss-deselect')

  ssDeselect && ssDeselect.click()
}

const handleCellMouseDown = function(table, selectionContainer, otherSelectionContainer, propName) {
  table.addHook('afterOnCellMouseDown', (event, coords, td) => {
    if (event.button !== 0) return

    const prop = table.colToProp(coords.col)

    if (prop === propName) {
      closeOtherSelection(otherSelectionContainer)
      selectionContainer.classList.add('active')

      assignSelectionRow(selectionContainer, coords.row)
      deSelect(selectionContainer)
    }
  })
}

const divisionSelection = function(table, selectionContainer, otherSelectionContainer) {
  handleCellMouseDown(table, selectionContainer, otherSelectionContainer, 'division_id')
}

const locationSelection = function(table, selectionContainer, otherSelectionContainer) {
  handleCellMouseDown(table, selectionContainer, otherSelectionContainer, 'working_location_ids')
}

const englishLevelSelection = function(table, selectionContainer, otherSelectionContainer) {
  handleCellMouseDown(table, selectionContainer, otherSelectionContainer, 'english_level_id')
}

const workingHourStartSelection = function(table, selectionContainer, otherSelectionContainer) {
  handleCellMouseDown(table, selectionContainer, otherSelectionContainer, 'working_hour_start')
}

const workingHourEndSelection = function(table, selectionContainer, otherSelectionContainer) {
  handleCellMouseDown(table, selectionContainer, otherSelectionContainer, 'working_hour_end')
}

module.exports = { divisionSelection, locationSelection, englishLevelSelection,
  workingHourStartSelection, workingHourEndSelection }
